<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgMRStatus ref="dlgMRStatus" :visible="dlgMRStatusVisible" @close="dlgMRStatusClose"></DlgMRStatus>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Reimbursement Request Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel1">
                        <v-toolbar-title>Reimbursement Info</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="(info,index) in  mrDetailField" :key="'mrIndex'+index" outlined v-model="mrDetail[info.fieldName]" :label="info.name" :readonly="info.readOnly"></v-text-field>
                                <v-chip v-if="mrDetail['req_status']" :color="vchipColor[mrDetail['req_status']].color">{{ mrDetail['req_status'] }}</v-chip>
                                <v-btn icon @click="showDlgMRStatus"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>

                <v-toolbar  dark class="primary mt-5"><v-toolbar-title>Item List</v-toolbar-title>
                </v-toolbar>

                    <v-container class="my-5">
                        <v-card>
                        <v-card-title>
                        <v-text-field
                            v-model="tblSearch"
                            append-icon="mdi-magnify"
                            label="Search data below"
                            hide-details
                            outlined
                        ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        item-key="'index' + merchant_reimbursement_item_id"
                        :headers="tblHeaders"
                        :items="tblBody"
                        :search="tblSearch"
                        :loading = "loadingFlag"
                        loading-text="Loading... Please wait"
                        ></v-data-table>
                        </v-card>
                    </v-container>                  
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel3">
                        <v-toolbar-title>Status Time Line</v-toolbar-title>
                    </v-toolbar>

                                        <v-container class="pt-2 mt-3">
                        <v-card  class="mt-5">
                            <v-timeline align-top dense>
                                <template v-for="(timeLine,index) in mrStatusList">
                                <v-timeline-item  :key="'index' + index" small>
                                    <v-row class="pt-1">
                                        <v-col cols="12" sm="12" md="3">
                                            <div class="text-caption">{{ timeLine['created_date'] }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2">
                                            <div class="text-caption"><v-chip :color="vchipColor[timeLine['status']].color">{{ timeLine['status'] }}</v-chip></div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <div class="text-caption"><v-icon>mdi-calendar</v-icon>{{ timeLine['status_date'] }}</div>
                                            <div class="text-caption"><v-icon>mdi-file</v-icon>{{ timeLine['status_ref_no'] }}</div>
                                            
                                            <div class="text-caption"><v-icon>mdi-note-text</v-icon>{{ timeLine['remarks'] }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                        
                                        <strong>{{ timeLine['cces_subject'] }}</strong>
                                        <div class="text-caption">
                                            {{ timeLine['cces_description'] }}
                                        </div>
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>     
                            </template>               
                            </v-timeline>
                        </v-card>
                    </v-container>

                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgMRStatus from '@/components/DlgMRStatus'
import ApMerchantService from "@/services/ApMerchantService"
export default {
  components:{DlgMessage,DlgMRStatus},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Merchant',disabled: false,href:'#',},
              {text:'Reimbursement Request',disabled: false,href:'#',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            statusList:[],
            form:[],
            mrDetail:[],
            mrItemList:[],
            mrStatusList:[],
            mrDetailField:[
                {name:"Req Reference No.",fieldName:"req_ref_no",readOnly:true},
                {name:"Created Date",fieldName:"created_date",readOnly:true},
                {name:"Merchant Name",fieldName:"merchant_name",readOnly:true},
                {name:"Outlet Name",fieldName:"outlet_name",readOnly:true},
                {name:"Total Item",fieldName:"total_item",readOnly:true},
                {name:"Total Qty",fieldName:"total_qty",readOnly:true},
                {name:"Total Amount",fieldName:"total_amount",readOnly:true},                
            ],
            tblHeaders:[
                { text:"Item Code",value: "ci_code"  },
                { text:"Item Name", value:"ci_name" },
                { text:"Value", value:"ci_value" },
                { text:"Serial Number", value:"serial_number" },
                { text:"E-Voucher No", value:"evoucher_no" }
            ],

            tblBody:[],
            tblSearch:'',
            loadingFlag:false,

            routeParam:[],
            rules: {
                required: value => !!value || 'Required.',
                email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                integer: value => /^[0-9]+$/.test(value) || 'Number only',
                decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                pinNumber: value => value.length == 6 || 'require 6 digits number',
                searchWord: value => value.length >= 3 || 'require 3 or more letters',
            },
            stateList:[],
            vchipColor:{
                "NEW":{"color":"yellow"},
                "IN PROGRESS":{"color":"orange"},
                "APPROVED":{"color":"light-green"},
                "PAID":{"color":"green"},
            },
            dlgMRStatusVisible:false,

    }
  },
  async created() {
        this.routeParam['req_ref_no'] = this.$route.params.req_ref_no;
        this.routeParam['merchant_reimbursement_request_id'] = this.$route.params.merchant_reimbursement_request_id;
        this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let title = "View Reimbursement Request"
        let request = {
            req_ref_no:this.routeParam['req_ref_no'],
            merchant_reimbursement_request_id:this.routeParam['merchant_reimbursement_request_id']}
        try{
            ApMerchantService.view_request(request).then((res) => {
                this.mrDetail = res.data.mrDetail;
                for (const [key, value] of Object.entries(this.mrDetail)) {
                    if(value == null){
                        this.mrDetail[key] = "";
                    }
                }                
                this.tblBody = res.data.mrItemList;
                // this.mrItemList = res.data.mrItemList;
                this.mrStatusList = res.data.mrStatusList;


            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response);
            })
            ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
        }

    },
    dlgClose(){
        this.visible = false;        
    },

    showDlgMRStatus(){

        let mrStatusDetail = this.mrStatusList[0];        
        this.$refs.dlgMRStatus.dlgShow(mrStatusDetail);
        this.dlgMRStatusVisible = true;

    },
    dlgMRStatusClose(){
        this.dlgMRStatusVisible = false;
        this.viewDetail();
    },

  }
}
</script>